body {
  background: #f5f4f8;
}

table#brawlerList img {
  margin-right: 10px;
}
table#brawlerList tfoot {
  inset-block-end: 0;
  position: sticky;
  background: #fff;
  border-top: none;
  box-shadow: 0px -2px 2px 0px #e8e8eb;
}
